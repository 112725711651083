.link {
  display: inline;
  color: var(--marketplaceColor);
  font-family: Montserrat;
  font-weight: 500;
  color: #171717;
  font-size: 14px;
  text-transform: uppercase;

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}